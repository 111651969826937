import React from 'react';

import useClub from '../hooks/useClub';
import ProductGrid from '~/components/ProductGrid';
import { deliverFeeStickerGer, isProShop } from '../config';
import Alert from '../components/Alert';
import formatCurrency from '../utils/formatCurrency';

const IndexPage = () => {
  const { club } = useClub();
  const isPro = club && isProShop(club.id);
  const availabeProducts = (club && club.available_products) || [];

  return availabeProducts.length > 0 ? (
    <>
      <p>
        {!isPro ? (
          <>
            Euer eigener Shop mit euren Stars. Nach dem Sammelfieber ist euer
            Stardasein nicht beendet. Wir verewigen euch auf vielen tollen
            Produkten. Wenn der Verkauf im Markt beendet ist, könnt ihr auch bis
            zu 40 Sticker nachbestellen, um die Lücken in eurem Sammelalbum zu
            füllen.
          </>
        ) : (
          <>
            Wenn du es nicht geschafft hast, dir während des Verkaufszeitraums
            alle Sticker zu erbeuten, dann kannst du nun hier nachbessern. Ist
            zwar ein bisschen wie schummeln, aber das hat in der Schule ja auch
            jeder gemacht. Pro Bestellung können <b>insgesamt 40 Sticker</b>{' '}
            bestellt werden. Es fällt eine Bearbeitungsgebühr inkl. Versand von
            {formatCurrency(deliverFeeStickerGer)} pro Bestellung an. Die
            Lieferzeit kann bis zu 10 Werktage betragen.
          </>
        )}
      </p>
      <ProductGrid />
    </>
  ) : (
    <Alert type="info">
      Hier gibt es leider nichts zu bestellen! Vermutlich ist der
      Verkaufszeitraum bereits beendet.
    </Alert>
  );
};

export default IndexPage;
