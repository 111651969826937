import { useContext, useMemo } from 'react';

import StoreContext from '../context/StoreContext';

const useClubs = () => {
  const {
    store: { clubId, clubs },
  } = useContext(StoreContext);

  const club = useMemo(() => {
    return clubs.find(({ id }) => id === Number(clubId));
  }, [clubId, clubs]);

  return {
    club,
  };
};

export default useClubs;
