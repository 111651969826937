import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';

import { Grid, Product, Title, PriceTag } from './styles';
import formatCurrency from '../../utils/formatCurrency';
import useClub from '../../hooks/useClub';
import { GatsbyImage } from 'gatsby-plugin-image';

const ProductGrid = () => {
  const { club } = useClub();

  const availabeProducts = (club && club.available_products) || [];

  const { allShopifyProduct } = useStaticQuery(
    graphql`
      query {
        allShopifyProduct(sort: { fields: [createdAt], order: DESC }) {
          edges {
            node {
              id
              shopifyId
              title
              handle
              createdAt
              images {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
              variants {
                price
                availableForSale
              }
            }
          }
        }
      }
    `
  );

  const getPrice = price => formatCurrency(parseFloat(price ? price : 0));

  const allowedProductId = shopifyId => {
    return availabeProducts.includes(
      shopifyId
        .split('/')
        .pop()
    );
  };

  return (
    <Grid>
      {allShopifyProduct.edges ? (
        allShopifyProduct.edges.map(
          ({
            node: {
              id,
              shopifyId,
              handle,
              title,
              images: [firstImage],
              variants: [firstVariant, availableForSale],
            },
          }) =>
            allowedProductId(shopifyId) ? 
            (
              <Product key={id} data-testid={`qa-product`}>
                <Link to={`/product/${handle}/`} data-testid="qa-product-link">
                  {firstImage && (
                    <GatsbyImage
                      image={firstImage.gatsbyImageData}
                      alt={handle}
                    />
                  )}
                </Link>
                <Title>{title}</Title>
                {availableForSale && (
                  <PriceTag>{getPrice(firstVariant.price.amount)}</PriceTag>
                )}
              </Product>
            ) 
            : null
        )
      ) : (
        <p>No Products found!</p>
      )}
    </Grid>
  );
};

export default ProductGrid;
